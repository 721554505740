.bg-gradient-x-pink-orange
{
    background-image: -webkit-gradient(linear, right top, left top, from(#ee0979), to(#000));
    background-image: -webkit-linear-gradient(right, #ee0979, #000);
    background-image:    -moz-linear-gradient(right, #ee0979, #000);
    background-image:      -o-linear-gradient(right, #ee0979, #000);
    background-image:         linear-gradient(to left, #ee0979, #000);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-pink-orange
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, right top, left top, from(#ee0979), color-stop(50%, #000), to(#ee0979));
    background-image: -webkit-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%);
    background-image:    -moz-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%);
    background-image:      -o-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%);
    background-image:         linear-gradient(-90deg, #ee0979 0%, #000 50%, #ee0979 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-pink-orange.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #ee0979;
            box-shadow: 0 0 12px 0 #ee0979;
}
.btn-bg-gradient-x-pink-orange.active,
.btn-bg-gradient-x-pink-orange:active,
.btn-bg-gradient-x-pink-orange:hover
{
    background-image: -webkit-gradient(linear, right top, left top, from(#ee0979), color-stop(50%, #000), to(#ee0979)) !important;
    background-image: -webkit-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%) !important;
    background-image:    -moz-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%) !important;
    background-image:      -o-linear-gradient(right, #ee0979 0%, #000 50%, #ee0979 100%) !important;
    background-image:         linear-gradient(-90deg, #ee0979 0%, #000 50%, #ee0979 100%) !important; 
    background-position: left center;
}

body.vertical-layout[data-color=bg-gradient-x-pink-orange] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-pink-orange] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, right top, left top, from(#ee0979), to(#000));
    background-image: -webkit-linear-gradient(right, #ee0979, #000);
    background-image:    -moz-linear-gradient(right, #ee0979, #000);
    background-image:      -o-linear-gradient(right, #ee0979, #000);
    background-image:         linear-gradient(to left, #ee0979, #000);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-pink-orange] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-pink-orange] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, right top, left top, from(#ee0979), to(#000));
    background-image: -webkit-linear-gradient(right, #ee0979, #000);
    background-image:    -moz-linear-gradient(right, #ee0979, #000);
    background-image:      -o-linear-gradient(right, #ee0979, #000);
    background-image:         linear-gradient(to left, #ee0979, #000);
    background-repeat: repeat-x;
}

/* new gradinet */

.bg-gradient-x-black-lightgreen
{
    background-image: -webkit-gradient(linear, right top, left top, from(#4682B4), to(#000));
    background-image: -webkit-linear-gradient(right, #4682B4, #000);
    background-image:    -moz-linear-gradient(right, #4682B4, #000);
    background-image:      -o-linear-gradient(right, #4682B4, #000);
    background-image:         linear-gradient(to left, #4682B4, #000);
    background-repeat: repeat-x;
}

.btn-bg-gradient-x-black-lightgreen
{
    -webkit-transition: .5s;
       -moz-transition: .5s;
         -o-transition: .5s;
            transition: .5s; 

    color: #fff;
    border: none;
    background-image: -webkit-gradient(linear, right top, left top, from(#4682B4), color-stop(50%, #000), to(#4682B4));
    background-image: -webkit-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%);
    background-image:    -moz-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%);
    background-image:      -o-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%);
    background-image:         linear-gradient(-90deg, #4682B4 0%, #000 50%, #4682B4 100%);
    -webkit-background-size: 200% auto;
            background-size: 200% auto;
}
.btn-bg-gradient-x-black-lightgreen.btn-glow
{
    -webkit-box-shadow: 0 0 12px 0 #4682B4;
            box-shadow: 0 0 12px 0 #4682B4;
}
.btn-bg-gradient-x-black-lightgreen.active,
.btn-bg-gradient-x-black-lightgreen:active,
.btn-bg-gradient-x-black-lightgreen:hover
{
    background-image: -webkit-gradient(linear, right top, left top, from(#4682B4), color-stop(50%, #000), to(#4682B4)) !important;
    background-image: -webkit-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%) !important;
    background-image:    -moz-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%) !important;
    background-image:      -o-linear-gradient(right, #4682B4 0%, #000 50%, #4682B4 100%) !important;
    background-image:         linear-gradient(-90deg, #4682B4 0%, #000 50%, #4682B4 100%) !important; 
    background-position: left center;
}

body.vertical-layout[data-color=bg-gradient-x-black-lightgreen] .navbar-container,
body.vertical-layout[data-color=bg-gradient-x-black-lightgreen] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, right top, left top, from(#4682B4), to(#000));
    background-image: -webkit-linear-gradient(right, #4682B4, #000);
    background-image:    -moz-linear-gradient(right, #4682B4, #000);
    background-image:      -o-linear-gradient(right, #4682B4, #000);
    background-image:         linear-gradient(to left, #4682B4, #000);
    background-repeat: repeat-x;
}

body.horizontal-layout[data-color=bg-gradient-x-black-lightgreen] .navbar-horizontal,
body.horizontal-layout[data-color=bg-gradient-x-black-lightgreen] .content-wrapper-before
{
    background-image: -webkit-gradient(linear, right top, left top, from(#4682B4), to(#000));
    background-image: -webkit-linear-gradient(right, #4682B4, #000);
    background-image:    -moz-linear-gradient(right, #4682B4, #000);
    background-image:      -o-linear-gradient(right, #4682B4, #000);
    background-image:         linear-gradient(to left, #4682B4, #000);
    background-repeat: repeat-x;
}
