.task-active {
  /* border-bottom: 1px solid #ccc; */
  padding-top: 5px;
}

.task-active > * {
  /* margin: 0 !important; */
  /* padding: 0 !important; */
  /* border: 1px solid yellow !important; */
}

.styleTaskActive {
  color: white;
  background-color: #ee0979;
  padding: 4px 6px;
  /* display: inline; */
}

.styleTaskFinished { 
  color: grey; 
  text-decoration: line-through;
}


.styleRowActive {
  padding: 5px 0;
  background-color: #F8F8FF;
  /* border-bottom: 1px solid #dcdcdc; */
}


.styleRowFinished {
  padding-top: 5px;
  background-color: #F5F5F5;
}
